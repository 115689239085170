import React, {useState } from 'react';
import './Marker.css';

const Marker = (props) => {
    const [infoOpen, setInfoOpen] = useState(null);
    const { image, name, id, tooltip } = props;
    const handleClick = () => {
      //console.log(`You clicked on ${tooltip}`);
      setInfoOpen(true)
    };
    const handleClose = () => {
      setInfoOpen(false)
    };

    const imageUrl="/htbcontent/themes/htb-personal/assets/img/"+image;
    return (
      <>
      <div className="marker"
        style={{ width:"20px",height:"22px"}}
        title={name}
        onClick={handleClick}
      >
      <img src={imageUrl} draggable="false" style={{ width:"20px",height:"22px"}}/>
      </div>
      {infoOpen ?
      <div style={{ backgroundColor:"white",padding:"12px",position:"absolute",top:"0",left:"0",borderRadius:"8px",width:"160px",boxShadow:"0 2px 7px 1px rgba(0,0,0,0.3)",minHeight:"70px",zIndex:10,fontWeight:"300",fontSize:"13px",marginTop:"0",transform:"translate(-50%,-100%)"}} title={tooltip}>
        {tooltip}
        <button draggable="false" title="Close" onClick={handleClose} aria-label="Close" type="button" className="gm-ui-hover-effect" style={{background: "none",display: "block", border: "0px", margin: "0px", padding: "0px", position: "absolute", cursor: "pointer", userSelect: "none", top: "-6px", right: "-6px", width: "30px", height: "30px"}}>
        <img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A" style={{pointerEvents: "none", display: "block", width: "14px", height: "14px", margin: "8px"}} /></button>
      </div>
      : null }
      </>
    );
  };

  export default Marker;
