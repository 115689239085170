import React from "react"
import GoogleMap from '../../components/FundingGMap'

  function FundingGMap({ FundingMapTitle }){
  return (
      <GoogleMap />
  )
  }


export default FundingGMap
